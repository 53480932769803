<template>
  <el-card class="box-card">
    <el-row slot="header" align="middle" justify="space-between" type="flex">
      <b>Заказы и оплаты</b>
      <div style="display: flex; align-items: center">Фильтр:
        <!--        <date-picker-->
        <!--            v-model="params.date"-->
        <!--            :clearable="true"-->
        <!--            :picker-options="{-->
        <!--              firstDayOfWeek: 1-->
        <!--            }"-->
        <!--            format="yyyy-MM-dd"-->
        <!--            placeholder="Фильтр по дате"-->
        <!--            type="date"-->
        <!--            @change="filterByDate"-->
        <!--            @keyup.enter.native="filterByDate">-->
        <!--        </date-picker>-->


        <el-date-picker
            style="margin-left: 1rem"
            v-model="filterDate"
            type="daterange"
            range-separator="По"
            @change="filterByDate"
            @keyup.enter.native="filterByDate"
        >
        </el-date-picker>


      </div>
    </el-row>
    <el-tabs type="card" value="service" @tab-click="handleClick">
      <el-tab-pane label="Консультации" name="service"></el-tab-pane>
      <el-tab-pane label="Клиники " name="clinic"></el-tab-pane>
      <el-tab-pane label="Чекапы " name="checkup"></el-tab-pane>
    </el-tabs>
    <el-table v-loading="loading" :data="payments" border style="width: 100%">
      <el-table-column
          label="Номер заказа"
          prop="order_number"
      ></el-table-column>
      <el-table-column
          label="Сумма"
          prop="amount"
      >
        <template slot-scope="scope">{{ scope.row.amount }} тг.</template>
      </el-table-column>
      <el-table-column
          label="Пользователь"
          prop="user.name"


      >
        <template v-if="scope.row.user" slot-scope="scope">{{ scope.row.user.name }} {{ scope.row.user.surname }} {{
            scope.row.user.patronymic
          }}
        </template>
      </el-table-column>
      <el-table-column
          label="Номер телефона"
          prop="user.phone"


      >
        <template v-if="scope.row.user" slot-scope="scope">+{{ scope.row.user.phone }}</template>
      </el-table-column>
      <el-table-column
          v-if="this.paymentType == 'service'"
          label="Пользователь-врач"
          prop="model.doctor.name"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'service'"
          label="email врача"
          prop="model.doctor.email"
      ></el-table-column>

      <el-table-column
          v-if="this.paymentType == 'service'"
          label="Дата записи"
          prop="model.request_info.date"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'service'"
          label="Время записи"
          prop="model.request_info.time"
      ></el-table-column>


      <el-table-column
          v-if="this.paymentType == 'service'"
          label="Название услуги"
          prop="model.model.title"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'clinic'"
          label="Клиника"
          prop="model.clinic.users.name"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'clinic'"
          label="Email Клиники"
          prop="model.clinic.users.email"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'clinic'"
          label="Услуга"
          prop="model.title"
      ></el-table-column>

      <el-table-column
          v-if="this.paymentType == 'clinic'"
          label="Дата записи "
          prop="model.request_info.date"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'clinic'"
          label="Время записи "
          prop="model.request_info.time"
      ></el-table-column>


      <el-table-column
          v-if="this.paymentType == 'checkup'"
          label="Чекап"
          prop="model.title"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'checkup'"
          label="Название клиники"
          prop="model.clinic.name"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'checkup'"
          label="Email клиники"
          prop="model.clinic.email"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'checkup'"
          label="Дата записи"
          prop="model.request_info.date"
      ></el-table-column>
      <el-table-column
          v-if="this.paymentType == 'checkup'"
          label="Время записи"
          prop="model.request_info.time"
      ></el-table-column>


      <el-table-column
          label="Статус оплаты"
      >
        <template slot-scope="scope">
          <el-button
              v-if="scope.row.status == 1"
              type="success"
              size="small"
          >Оплачен
          </el-button>
          <el-button
              v-if="scope.row.status == -1"
              type="danger"
              size="small"
          >Ошибка
          </el-button>
          <el-button
              v-if="scope.row.status == 0"
              type="grey"
              size="small"
          >В ожидании
          </el-button>
          <el-button
              v-if="scope.row.status == 2"
              type="grey"
              size="small"
          >Возвращен
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="Дата оплаты"
          prop="date"
      >
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>
      <el-table-column
          label="Действия"
      >
        <template slot-scope="scope">
          <el-button
              :disabled="scope.row.status ===0"
              v-if="scope.row.status != 2"
              @click="revertPayment(scope)"
              type="danger"
              size="small"
          >Возврат
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :page-size="page.size"
        :total="page.total"
        :current-page="page.current_page"
        layout="prev, pager, next"
        @current-change="paginate"
    ></el-pagination>
  </el-card>
</template>

<script>
// import DatePicker from "@/components/DatePicker";
import moment from "moment";

export default {
  name: "DepartmentList",
  // components: {DatePicker},
  data() {
    return {
      loading: false,
      paymentType: 'service',
      page: {
        offset: 1,
        size: 15,
        total: 0
      },
      filterDate: '',
      params: {
        date: undefined
      },
      payments: []
    };
  },
  async created() {
    this.loading = true;
    await this.getPayments();
    this.loading = false;
  },
  filters: {
    moment: function (date) {
      return moment(date).format('D.MM.YYYY, hh:mm');
    }
  },

  methods: {
    filterByDate() {
      this.getPayments()
    },
    async revertPayment(scope) {
      this.$confirm(`Вы действительно хотите осуществить возврат по заказу ${scope.row.order_id}? Действие нельзя отменить`, "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning"
      })
          .then(() => {
            this.$http.post(
                `${process.env.VUE_APP_API_URL}/admin/payments/reverse`,
                {
                  orderId: scope.row.order_id,
                }
            );
            this.getPayments()
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Действие отменено"
            });
          });
    },
    handleClick(item) {
      this.paymentType = item.name;
      this.getPayments()
    },
    async paginate(event) {
      this.page.offset = event;
      await this.getPayments();
    },

    async getPayments() {
      this.loading = true
      this.payments = []

      var params = {
        page: this.page.offset,
      }
      if (this.filterDate[0] && this.filterDate[1]) {
        if (this.filterDate[0]) {
          var dateStart = new Date(this.filterDate[0])
          dateStart.setDate(dateStart.getDate() + 1)
        }
        if (this.filterDate[1]) {
          var dateEnd = new Date(this.filterDate[1])
          dateEnd.setDate(dateEnd.getDate() + 1)
        }

        params.dateStart = dateStart
        params.dateEnd = dateEnd
      }

      const result = await this.$http.get(`${process.env.VUE_APP_API_URL}/admin/payments/${this.paymentType}`,
          {
            params
          }
      );
      this.payments = result.data.data;
      // this.page.total = result.data.pagination.total_pages;
      this.page.size = result.data.pagination.per_page;
      this.page.current_page = result.data.pagination.current_page;
      this.page.total = result.data.pagination.total;
      this.loading = false
    },
  },
  watch: {
    async filterDate() {
      this.loading = true;
      await this.getPayments();
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
